import { IStyle } from '@flarie/common';
import { StyleConverterService } from 'services/StyleConverterService';

interface IStyleObject {
  gameImageBackgroundStyle?: Partial<IStyle>;
}

export const getGameStyle = ({ gameImageBackgroundStyle }: IStyleObject) => {
  const styleConverter = new StyleConverterService();
  const convertedGameImageBackgroundStyle = styleConverter.convertIStyleToCSS(gameImageBackgroundStyle as IStyle);

  return {
    gameImageBackgroundStyle: {
      ...convertedGameImageBackgroundStyle,
    },
  };
};
