/* eslint-disable dot-notation */
import { IGameCenterRewardSettings, TextDecorationType } from '@flarie/common';
import { StyleConverterService } from 'services/StyleConverterService';

export const getRewardDetailStyle = (rewardSettings: IGameCenterRewardSettings) => {
  const pageBackground: { backgroundImage?: string; background?: string } = {};
  if (rewardSettings.backgroundImage) {
    pageBackground['backgroundImage'] = `url(${rewardSettings.backgroundImage})`;
  } else {
    pageBackground['background'] = `#${rewardSettings.backgroundColor}`;
  }

  const styleConverter = new StyleConverterService({ applyTransform: true });

  const convertedHeaderFontStyle = styleConverter.convertIStyleToCSS(rewardSettings.headerFontStyle);
  const headerFontStyle = {
    color: convertedHeaderFontStyle?.color,
    fontSize: convertedHeaderFontStyle?.fontSize,
    textAlign: convertedHeaderFontStyle?.textAlign,
    letterSpacing: convertedHeaderFontStyle.letterSpacing || '',
    lineHeight: convertedHeaderFontStyle.lineHeight || '',
    fontFamily: 'inherit',
    fontWeight: convertedHeaderFontStyle.fontWeight || 'bold',
    textShadow: convertedHeaderFontStyle.textShadow || '',
    textDecoration: convertedHeaderFontStyle.textDecoration || TextDecorationType.NONE,
  };

  const convertedDescriptionTextStyle = styleConverter.convertIStyleToCSS(rewardSettings.descriptionTextStyle);
  const descriptionTextStyle = {
    color: convertedDescriptionTextStyle?.color,
    fontSize: convertedDescriptionTextStyle?.fontSize,
    textAlign: convertedDescriptionTextStyle?.textAlign,
    letterSpacing: convertedDescriptionTextStyle.letterSpacing || '',
    lineHeight: convertedDescriptionTextStyle.lineHeight || '',
    fontFamily: 'inherit',
    fontWeight: convertedDescriptionTextStyle.fontWeight || 'normal',
    textShadow: convertedDescriptionTextStyle.textShadow || '',
    textDecoration: convertedDescriptionTextStyle.textDecoration || TextDecorationType.NONE,
  };

  const convertedButtonStyle = styleConverter.convertIStyleToCSS({
    ...rewardSettings.buttonStyle,
    shape: {
      ...rewardSettings.buttonStyle?.shape,
    },
  });

  const buttonStyle = {
    backgroundColor: convertedButtonStyle?.backgroundColor,
    ...(convertedButtonStyle?.background
      ? {
          background: convertedButtonStyle?.background,
        }
      : {}),
    ...(convertedButtonStyle?.backgroundImage
      ? {
          backgroundImage: convertedButtonStyle.backgroundImage,
          backgroundSize: convertedButtonStyle.backgroundSize,
          backgroundRepeat: convertedButtonStyle.backgroundRepeat,
        }
      : {}),
    color: convertedButtonStyle?.color,
    borderRadius: {
      borderTopLeftRadius: convertedButtonStyle?.borderTopLeftRadius,
      borderTopRightRadius: convertedButtonStyle?.borderTopRightRadius,
      borderBottomRightRadius: convertedButtonStyle?.borderBottomRightRadius,
      borderBottomLeftRadius: convertedButtonStyle?.borderBottomLeftRadius,
    },
    padding: {
      paddingTop: convertedButtonStyle?.paddingTop,
      paddingRight: convertedButtonStyle?.paddingRight,
      paddingBottom: convertedButtonStyle?.paddingBottom,
      paddingLeft: convertedButtonStyle?.paddingLeft,
    },
    borderStyle: convertedButtonStyle.borderStyle,
    borderColor: convertedButtonStyle.borderColor,
    borderWidth: convertedButtonStyle.borderWidth,

    top: convertedButtonStyle?.top,
    left: convertedButtonStyle?.left,
    transform: convertedButtonStyle?.transform,
    width: convertedButtonStyle?.width,
    height: convertedButtonStyle?.height,

    fontSize: convertedButtonStyle?.fontSize,
    textAlign: convertedButtonStyle?.textAlign,
    letterSpacing: convertedButtonStyle.letterSpacing || '',
    lineHeight: convertedButtonStyle.lineHeight || '',
    fontFamily: 'inherit',
    fontWeight: convertedButtonStyle.fontWeight || 'normal',
    textShadow: convertedButtonStyle.textShadow || '',
    textDecoration: convertedButtonStyle.textDecoration || TextDecorationType.NONE,
    boxShadow: convertedButtonStyle.boxShadow || '',
  };

  return { pageBackground, headerFontStyle, descriptionTextStyle, buttonStyle };
};
