/* eslint-disable react/no-array-index-key */
import { IGameCenterRewardModule } from '@flarie/common';
import Reward from 'components/reward/Reward';
import Section from 'components/section/Section';
import { IReward } from 'interfaces/Reward';
import { FC } from 'react';

interface Props extends IGameCenterRewardModule {
  rewards: IReward[];
}

const RewardSection: FC<Props> = ({
  rewards,
  headerTitle,
  headerTitleStyle,
  descriptionText,
  descriptionStyle,
  moduleId,
}) => {
  return (
    <Section
      title={headerTitle}
      headerFontStyle={headerTitleStyle}
      descriptionText={descriptionText}
      descriptionStyle={descriptionStyle}
      moduleId={moduleId}
    >
      <div className="grid grid-cols-2 gap-x-5">
        {rewards.map((reward: IReward, i: number) => (
          <Reward key={`reward${i}`} {...reward} />
        ))}
      </div>
    </Section>
  );
};
export default RewardSection;
