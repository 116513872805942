import { IGameCenterTextModule } from '@flarie/common';
import Section from 'components/section/Section';
import { FC } from 'react';

const TextSection: FC<IGameCenterTextModule> = ({
  headerTitle = '',
  descriptionText = '',
  headerTitleStyle,
  descriptionStyle,
  moduleId,
}) => {
  return (
    <Section
      title={headerTitle}
      headerFontStyle={headerTitleStyle}
      descriptionText={descriptionText}
      descriptionStyle={descriptionStyle}
      moduleId={moduleId}
    >
      <span></span>
    </Section>
  );
};
export default TextSection;
