/* eslint-disable class-methods-use-this */
import {
  IStyleUnit,
  StyleUnitType,
  IBorderRadius,
  IPadding,
  IBorder,
  IColorStyle,
  ColorPickerType,
  IShapeStyle,
  IFontStyle,
  FontWeightType,
  TextDecorationType,
  IStyle,
  ObjectFitType,
} from '@flarie/common';

export interface CSSProperties {
  [key: string]: string | number | undefined;
}

export interface IStyleConverterOptions {
  applyTransform?: boolean;
}

/**
 * Service to convert IStyle to CSSProperties
 * @param options - applyTransform - by default its false, means that only 50% values are transformed, if true top/left values are transformed no matter they are 50% or not
 */
export class StyleConverterService {
  /**
   * Creates an instance of StyleConverter
   * @param applyTransform - by default, its false means that only 50% values are transformed, if true top/left values are transformed no matter they are 50% or not
   */

  private applyTransform = false;

  constructor(options?: IStyleConverterOptions) {
    this.applyTransform = options?.applyTransform ?? false;
  }

  private formatStyleUnit(unit: IStyleUnit | undefined, zeroToAuto: boolean = false) {
    if (!unit) return undefined;

    if (unit.unit === StyleUnitType.NONE) return unit.value;
    if (zeroToAuto && unit.value === 0) return 'auto';

    return `${unit.value}${unit.unit}`;
  }

  private formatBorderRadius(borderRadius: IBorderRadius): CSSProperties {
    return {
      borderTopLeftRadius: this.formatStyleUnit(borderRadius.topLeft),
      borderTopRightRadius: this.formatStyleUnit(borderRadius.topRight),
      borderBottomRightRadius: this.formatStyleUnit(borderRadius.bottomRight),
      borderBottomLeftRadius: this.formatStyleUnit(borderRadius.bottomLeft),
    };
  }

  private formatPadding(padding: IPadding): CSSProperties {
    const style = {} as CSSProperties;

    if (padding.top.value) {
      style.paddingTop = this.formatStyleUnit(padding.top);
    }

    if (padding.right.value) {
      style.paddingRight = this.formatStyleUnit(padding.right);
    }

    if (padding.bottom.value) {
      style.paddingBottom = this.formatStyleUnit(padding.bottom);
    }

    if (padding.left.value) {
      style.paddingLeft = this.formatStyleUnit(padding.left);
    }

    return style;
  }

  private formatBorder(border: IBorder): CSSProperties {
    return {
      borderStyle: border.style,
      borderColor: border.color,
      borderWidth: this.formatStyleUnit(border.width),
    };
  }

  private formatColorStyle(colorStyle: IColorStyle): CSSProperties {
    const style: CSSProperties = {};

    switch (colorStyle.type) {
      case ColorPickerType.SINGLE:
        style.backgroundColor = colorStyle.value;
        break;
      case ColorPickerType.GRADIENT:
        style.background = colorStyle.value;
        break;
      case ColorPickerType.IMAGE:
        style.backgroundImage = `url(${colorStyle.value})`;
        style.backgroundSize = colorStyle?.objectFit || ObjectFitType.CONTAIN;
        style.backgroundRepeat = 'no-repeat';
        break;
      default:
        break;
    }

    return style;
  }

  private adjustTransform(topUnit?: IStyleUnit, leftUnit?: IStyleUnit): string | undefined {
    if (!topUnit && !leftUnit) return undefined;

    const calculateTransform = (unit?: IStyleUnit) =>
      unit && (unit.value === 50 || this.applyTransform) ? `-${unit.value}${unit.unit}` : '0';

    const topTransform = calculateTransform(topUnit);
    const leftTransform = calculateTransform(leftUnit);

    return topTransform === '0' && leftTransform === '0' ? undefined : `translate(${leftTransform}, ${topTransform})`;
  }

  private formatShapeStyle(shape: IShapeStyle): CSSProperties {
    const style: CSSProperties = {};

    if (shape.width !== undefined) {
      style.width = this.formatStyleUnit(shape.width, true);
    }

    if (shape.height !== undefined) {
      style.height = this.formatStyleUnit(shape.height, true);
    }

    if (shape.dropShadow !== undefined) {
      style.boxShadow = shape.dropShadow;
    }

    if (shape.position) {
      style.position = shape.position;
    }

    if (shape?.top !== undefined) {
      style.top = shape.top && this.formatStyleUnit(shape.top);
      style.left = shape.left && this.formatStyleUnit(shape.left);
      style.transform = shape.top || shape.left ? this.adjustTransform(shape.top, shape.left) : undefined;
    }

    if (shape.padding !== undefined) {
      if (
        shape.padding.bottom.value ||
        shape.padding.top.value ||
        shape.padding.left.value ||
        shape.padding.right.value
      ) {
        Object.assign(style, this.formatPadding(shape.padding));
      }
    }
    if (shape.borderRadius !== undefined) Object.assign(style, this.formatBorderRadius(shape.borderRadius));
    if (shape.border !== undefined) Object.assign(style, this.formatBorder(shape.border));

    return style;
  }

  private formatFontStyle(font: IFontStyle): CSSProperties {
    const style: CSSProperties = {};

    if (font.fontWeight) {
      style.fontWeight = font.fontWeight === FontWeightType.BOLD ? 'bold' : 'normal';
    }
    if (font.fontSize) {
      style.fontSize = this.formatStyleUnit(font.fontSize);
    }
    if (font.lineHeight) {
      style.lineHeight = this.formatStyleUnit(font.lineHeight);
    }
    if (font.textAlign) {
      style.textAlign = font.textAlign;
    }
    if (font.letterSpacing) {
      style.letterSpacing = this.formatStyleUnit(font.letterSpacing, true);
    }
    if (font.textDecoration) {
      style.textDecoration = font.textDecoration === TextDecorationType.AUTO ? 'none' : font.textDecoration;
    }
    if (font.dropShadow) {
      style.textShadow = font.dropShadow;
    }
    if (font.color) {
      if (font.color.type === ColorPickerType.SINGLE) {
        style.color = font.color.value;
      }
    }

    return style;
  }

  private parseCSSString(customCSS: string): CSSProperties {
    const css: CSSProperties = {};

    customCSS
      .split(';') // Split into individual rules
      .map((rule) => rule.trim()) // Trim whitespace
      .filter((rule) => rule && !rule.startsWith('/*')) // Ignore empty lines and comments
      .forEach((rule) => {
        const [property, value] = rule.split(':').map((s) => s.trim());
        if (property && value) {
          // Convert CSS property names (e.g., "flex-direction") to camelCase (e.g., "flexDirection")
          const camelCaseProperty = property.replace(/-([a-z])/g, (_, char) => char.toUpperCase());
          css[camelCaseProperty as keyof CSSProperties] = value;
        }
      });

    return css;
  }

  public convertIStyleToCSS(style: IStyle): CSSProperties {
    const reactStyle: CSSProperties = {};

    if (style?.font) {
      Object.assign(reactStyle, this.formatFontStyle(style.font));
    }

    if (style.shape) {
      Object.assign(reactStyle, this.formatShapeStyle(style.shape));
    }

    if (style.backgroundColor) {
      Object.assign(reactStyle, this.formatColorStyle(style.backgroundColor));
    }

    if (style?.customCSS) {
      const cssStyles = this.parseCSSString(style.customCSS);
      Object.assign(reactStyle, cssStyles);
    }

    return reactStyle;
  }
}
