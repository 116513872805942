// TODO: remove this file after style-bar-2.0 migration

/* eslint-disable no-restricted-syntax */
/* eslint-disable no-prototype-builtins */
/* eslint-disable no-console */
/* eslint-disable complexity */
/* eslint-disable no-magic-numbers */
import * as colorConvert from 'color-convert';
import { keys, isObject, merge } from 'lodash';

import {
  Alignment,
  BorderStyleType,
  ColorPickerType,
  IBorderRadius,
  IPadding,
  IStyleUnit,
  PositionType,
  StyleUnitType,
  IStyle,
} from '@flarie/common';

const hexToRgb = (hexColor: string, opacity: number = 1): string => {
  if (!hexColor) {
    return '';
  }

  // Normalize the hex color by removing the leading '#' if present
  let normalizedHex = hexColor?.startsWith('#') ? hexColor.slice(1) : hexColor;

  if (normalizedHex.length === 3) {
    normalizedHex = Array.from(normalizedHex)
      .map((char) => char + char)
      .join('');
  }

  if (normalizedHex.length !== 6) {
    return '';
  }

  try {
    const [r, g, b] = colorConvert.hex.rgb(normalizedHex);
    return `rgba(${r},${g},${b},${opacity})`;
  } catch (error) {
    throw new Error(`Failed to convert hex to RGB: hexColor: ${hexColor} ${JSON.stringify(error)}`);
  }
};

const parseStyleUnit = (value: string): IStyleUnit => {
  if (value.endsWith(StyleUnitType.PERCENTAGE)) {
    return { value: Number.parseFloat(value), unit: StyleUnitType.PERCENTAGE };
  }

  return { value: Number.parseFloat(value), unit: StyleUnitType.PIXEL };
};

const parsePadding = (value: string): IPadding => {
  const singlePadding = parseStyleUnit(value);
  return { top: singlePadding, right: singlePadding, bottom: singlePadding, left: singlePadding };
};

const parseBorderRadius = (value: string): IBorderRadius => {
  const singleRadius = parseStyleUnit(value);

  return { topLeft: singleRadius, topRight: singleRadius, bottomRight: singleRadius, bottomLeft: singleRadius };
};

// eslint-disable-next-line complexity
const oldStyleToNewStyleMapper = (oldStyle: any): IStyle => {
  try {
    const newStyle: IStyle = {};

    // Map font-related properties
    if (
      ['color', 'fontSize', 'textAlign', 'letterSpacing', 'textShadow', 'font'].some((prop) =>
        oldStyle.hasOwnProperty(prop),
      )
    ) {
      newStyle.font = {
        ...(oldStyle.hasOwnProperty('font') && { fontFamily: oldStyle.font || '' }),
        ...(oldStyle.hasOwnProperty('fontSize') && {
          fontSize: oldStyle?.fontSize
            ? parseStyleUnit(oldStyle.fontSize)
            : ({ value: '', unit: StyleUnitType.PIXEL } as unknown as IStyleUnit),
        }),
        ...(oldStyle.hasOwnProperty('textAlign') && {
          textAlign: oldStyle.textAlign ? (oldStyle.textAlign as Alignment) : Alignment.CENTER,
        }),
        ...(oldStyle.hasOwnProperty('letterSpacing') && {
          letterSpacing: parseStyleUnit(oldStyle.letterSpacing || '0px'),
        }),
        ...(oldStyle.hasOwnProperty('textShadow') && { dropShadow: oldStyle.textShadow || '' }),
        ...(oldStyle.hasOwnProperty('color') && {
          color: { type: ColorPickerType.SINGLE, value: oldStyle.color ? hexToRgb(oldStyle.color) : '' },
        }),
      };
    }

    // Map Shape-related properties
    if (
      ['top', 'left', 'padding', 'borderRadius', 'borderWidth', 'position'].some((prop) =>
        oldStyle.hasOwnProperty(prop),
      )
    ) {
      newStyle.shape = {
        ...(oldStyle.hasOwnProperty('top') && {
          top: oldStyle?.top
            ? { ...parseStyleUnit(oldStyle.top), unit: StyleUnitType.PERCENTAGE }
            : ({ value: '', unit: StyleUnitType.PERCENTAGE } as unknown as IStyleUnit),
        }),
        ...(oldStyle.hasOwnProperty('left') && {
          left: oldStyle?.left
            ? { ...parseStyleUnit(oldStyle.left), unit: StyleUnitType.PERCENTAGE }
            : ({ value: '', unit: StyleUnitType.PERCENTAGE } as unknown as IStyleUnit),
        }),
        ...(oldStyle.hasOwnProperty('padding') && { padding: parsePadding(oldStyle.padding || '0px') }),
        ...(oldStyle.hasOwnProperty('borderRadius') && {
          borderRadius: parseBorderRadius(oldStyle.borderRadius || '0px'),
        }),
        ...(oldStyle.hasOwnProperty('borderWidth') && {
          border: {
            width: parseStyleUnit(oldStyle.borderWidth || '0px'),
            style: BorderStyleType.SOLID,
            color: '#000000',
          },
        }),
        ...(oldStyle.hasOwnProperty('position') && { position: oldStyle.position as PositionType }),
      };
    }

    // Map backgroundColor
    if (oldStyle.hasOwnProperty('backgroundColor')) {
      newStyle.backgroundColor = {
        type: ColorPickerType.SINGLE,
        value: oldStyle.backgroundColor ? hexToRgb(oldStyle.backgroundColor) : '',
      };
    }

    return newStyle;
  } catch (error) {
    throw new Error(`styleMigrationUtils.oldStyleToNewStyleMapper: error: ${JSON.stringify(error)}`);
  }
};

/**
 * Synchronizes two objects by keeping only the properties from the first object (objA)
 * that exist in the second object (objB), while maintaining the values from objA.
 * Works recursively for nested objects.
 * */
function syncObjects<T extends object, U extends object>(objA: T, objB: U): Partial<T> {
  if (!objA || !objB) {
    return {} as U;
  }

  const result: { [key: string]: any } = {};

  // Iterate through all keys in object B
  for (const key of keys(objB)) {
    const valueB = objB[key as keyof U];

    if (key in objA) {
      const valueA = objA[key as keyof T];

      // If both values are objects, recursively sync them
      if (isObject(valueA) && isObject(valueB)) {
        result[key] = syncObjects(valueA as object, valueB as object);
      } else {
        // If not objects, keep the value from object A
        result[key] = valueA;
      }
    } else {
      // If key doesn't exist in objA, use value from objB
      result[key] = valueB;
    }
  }

  return result as U;
}

export const mapStyleData = (defaultValue?: IStyle, currentValue?: any, lineHeightMultiply?: number): IStyle => {
  try {
    if (!currentValue || !defaultValue) {
      return { ...defaultValue };
    }

    // skipped if the current value already converted
    if (currentValue.hasOwnProperty('customCSS') || currentValue.hasOwnProperty('shape')) {
      return currentValue as unknown as IStyle;
    }
    if (currentValue.hasOwnProperty('backgroundColor') && currentValue?.backgroundColor.hasOwnProperty('type')) {
      return currentValue as unknown as IStyle;
    }
    if (currentValue.hasOwnProperty('font') && currentValue?.font.hasOwnProperty('fontSize')) {
      return currentValue as unknown as IStyle;
    }

    const result = oldStyleToNewStyleMapper(currentValue);

    const mergedValue: IStyle = merge({}, { ...defaultValue }, result);
    const mergedWithDefaultValue = syncObjects(mergedValue, defaultValue);

    if (
      lineHeightMultiply &&
      mergedWithDefaultValue?.font?.lineHeight?.unit &&
      mergedWithDefaultValue?.font?.fontSize?.value
    ) {
      // lineHeight = fontSize * lineHeightMultiply
      mergedWithDefaultValue.font.lineHeight.value = Math.round(
        mergedWithDefaultValue.font.fontSize.value * lineHeightMultiply,
      );
    }

    return { ...mergedWithDefaultValue };
  } catch {
    console.error('styleMigrationUtils error while mapping style data', defaultValue, currentValue);
    return { ...defaultValue };
  }
};
