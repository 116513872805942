import { Alignment, IGameCenterPopUp, TextDecorationType } from '@flarie/common';
import { StyleConverterService } from 'services/StyleConverterService';

export const getPopUpStyle = (popup: IGameCenterPopUp) => {
  const styleConverter = new StyleConverterService({ applyTransform: true });

  const convertedConfirmButtonStyle = styleConverter.convertIStyleToCSS(popup?.confirmButtonStyle);
  const convertedCloseButtonStyle = styleConverter.convertIStyleToCSS(popup?.closeButtonStyle);
  const convertedNextButtonStyle = styleConverter.convertIStyleToCSS(popup?.nextButtonStyle);

  return {
    closeButtonStyle: {
      color: convertedCloseButtonStyle.color,
      fontSize: convertedCloseButtonStyle.fontSize,
      textAlign: convertedCloseButtonStyle?.textAlign || Alignment.CENTER,
      letterSpacing: convertedCloseButtonStyle.letterSpacing || '',
      lineHeight: convertedCloseButtonStyle.lineHeight || '',
      fontFamily: 'inherit',
      fontWeight: convertedCloseButtonStyle.fontWeight || 'bold',
      textShadow: convertedCloseButtonStyle.textShadow || '',
      textDecoration: convertedCloseButtonStyle.textDecoration || TextDecorationType.NONE,

      width: convertedCloseButtonStyle?.width,
      maxWidth: '100%',
      height: convertedCloseButtonStyle?.height,

      borderTopLeftRadius: convertedCloseButtonStyle?.borderTopLeftRadius,
      borderTopRightRadius: convertedCloseButtonStyle?.borderTopRightRadius,
      borderBottomRightRadius: convertedCloseButtonStyle?.borderBottomRightRadius,
      borderBottomLeftRadius: convertedCloseButtonStyle?.borderBottomLeftRadius,

      paddingTop: convertedCloseButtonStyle?.paddingTop,
      paddingRight: convertedCloseButtonStyle?.paddingRight,
      paddingBottom: convertedCloseButtonStyle?.paddingBottom,
      paddingLeft: convertedCloseButtonStyle?.paddingLeft,

      borderStyle: convertedCloseButtonStyle?.borderStyle,
      borderColor: convertedCloseButtonStyle?.borderColor,
      borderWidth: convertedCloseButtonStyle?.borderWidth,

      boxShadow: convertedCloseButtonStyle.boxShadow || '',
      backgroundColor: convertedCloseButtonStyle?.backgroundColor,
      ...(convertedCloseButtonStyle?.background
        ? {
            background: convertedCloseButtonStyle?.background,
          }
        : {}),
      ...(convertedCloseButtonStyle?.backgroundImage
        ? {
            backgroundImage: convertedCloseButtonStyle.backgroundImage,
            backgroundSize: convertedCloseButtonStyle.backgroundSize,
            backgroundRepeat: convertedCloseButtonStyle.backgroundRepeat,
          }
        : {}),
    },
    confirmButtonStyle: {
      color: convertedConfirmButtonStyle.color,
      fontSize: convertedConfirmButtonStyle.fontSize,
      textAlign: convertedConfirmButtonStyle?.textAlign || Alignment.CENTER,
      letterSpacing: convertedConfirmButtonStyle.letterSpacing || '',
      lineHeight: convertedConfirmButtonStyle.lineHeight || '',
      fontFamily: 'inherit',
      fontWeight: convertedConfirmButtonStyle.fontWeight || 'bold',
      textShadow: convertedConfirmButtonStyle.textShadow || '',
      textDecoration: convertedConfirmButtonStyle.textDecoration || TextDecorationType.NONE,

      width: convertedConfirmButtonStyle?.width,
      maxWidth: '100%',
      height: convertedConfirmButtonStyle?.height,

      borderTopLeftRadius: convertedConfirmButtonStyle?.borderTopLeftRadius,
      borderTopRightRadius: convertedConfirmButtonStyle?.borderTopRightRadius,
      borderBottomRightRadius: convertedConfirmButtonStyle?.borderBottomRightRadius,
      borderBottomLeftRadius: convertedConfirmButtonStyle?.borderBottomLeftRadius,

      paddingTop: convertedConfirmButtonStyle?.paddingTop,
      paddingRight: convertedConfirmButtonStyle?.paddingRight,
      paddingBottom: convertedConfirmButtonStyle?.paddingBottom,
      paddingLeft: convertedConfirmButtonStyle?.paddingLeft,

      borderStyle: convertedConfirmButtonStyle?.borderStyle,
      borderColor: convertedConfirmButtonStyle?.borderColor,
      borderWidth: convertedConfirmButtonStyle?.borderWidth,

      boxShadow: convertedConfirmButtonStyle.boxShadow || '',
      backgroundColor: convertedConfirmButtonStyle?.backgroundColor,

      ...(convertedConfirmButtonStyle?.background
        ? {
            background: convertedConfirmButtonStyle?.background,
          }
        : {}),
      ...(convertedConfirmButtonStyle?.backgroundImage
        ? {
            backgroundImage: convertedConfirmButtonStyle?.backgroundImage,
            backgroundSize: convertedConfirmButtonStyle?.backgroundSize,
            backgroundRepeat: convertedConfirmButtonStyle?.backgroundRepeat,
          }
        : {}),
    },
    nextButtonStyle: {
      color: convertedNextButtonStyle.color,
      fontSize: convertedNextButtonStyle.fontSize,
      textAlign: convertedNextButtonStyle?.textAlign || Alignment.CENTER,
      letterSpacing: convertedNextButtonStyle?.letterSpacing || '',
      lineHeight: convertedNextButtonStyle?.lineHeight || '',
      fontFamily: 'inherit',
      fontWeight: convertedNextButtonStyle.fontWeight || 'bold',
      textShadow: convertedNextButtonStyle.textShadow || '',
      textDecoration: convertedNextButtonStyle.textDecoration || TextDecorationType.NONE,

      width: convertedNextButtonStyle?.width,
      maxWidth: '100%',
      height: convertedNextButtonStyle?.height,

      borderTopLeftRadius: convertedNextButtonStyle?.borderTopLeftRadius,
      borderTopRightRadius: convertedNextButtonStyle?.borderTopRightRadius,
      borderBottomRightRadius: convertedNextButtonStyle?.borderBottomRightRadius,
      borderBottomLeftRadius: convertedNextButtonStyle?.borderBottomLeftRadius,

      paddingTop: convertedNextButtonStyle?.paddingTop,
      paddingRight: convertedNextButtonStyle?.paddingRight,
      paddingBottom: convertedNextButtonStyle?.paddingBottom,
      paddingLeft: convertedNextButtonStyle?.paddingLeft,

      borderStyle: convertedNextButtonStyle?.borderStyle,
      borderColor: convertedNextButtonStyle?.borderColor,
      borderWidth: convertedNextButtonStyle?.borderWidth,

      boxShadow: convertedNextButtonStyle.boxShadow || '',
      backgroundColor: convertedNextButtonStyle?.backgroundColor,
      ...(convertedNextButtonStyle?.background
        ? {
            background: convertedNextButtonStyle?.background,
          }
        : {}),
      ...(convertedNextButtonStyle?.backgroundImage
        ? {
            backgroundImage: convertedNextButtonStyle.backgroundImage,
            backgroundSize: convertedNextButtonStyle.backgroundSize,
            backgroundRepeat: convertedNextButtonStyle.backgroundRepeat,
          }
        : {}),
    },
    pageStyle: {
      backgroundColor: `#${popup?.backgroundColor}`,
      color: `#${popup?.textColor}`,
    },
  };
};
