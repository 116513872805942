import { FormInputType } from '@flarie/common';
import React from 'react';
import { Input } from 'components/common/input/Input';
import { BusinessRegionInput } from 'components/player-parameter-form/BusinessRegionInput';
import CountryInput from 'components/player-parameter-form/CountryInput';
import { GenderInput } from 'components/player-parameter-form/GenderInput';
import { PhoneInput } from 'components/player-parameter-form/PhoneInput';

interface Props {
  value: string | number;
  type: string;
  placeholder: string;
  onChange: (value: string | number) => void;
}

export const UnknownPlayerInput: React.FC<Props> = ({ value, type, placeholder, onChange }) => {
  // if (type === FormInputType.EMAIL) {
  //   return <div />;
  // }
  // if (type === FormInputType.COUNTRY) {
  //   return <CountryInput value={value as string} onChange={onChange} placeholder={placeholder} />;
  // }
  // if (type === FormInputType.PHONE) {
  //   return <PhoneInput value={value as string} onChange={onChange} placeholder={placeholder} />;
  // }
  // return <Input value={value as string} onChange={onChange} placeholder={placeholder} />;

  if (type === FormInputType.EMAIL) {
    return <div />;
  }
  if (type === FormInputType.COUNTRY) {
    return <CountryInput value={value as string} onChange={onChange} placeholder={placeholder} />;
  }
  if (type === FormInputType.PHONE) {
    return <PhoneInput value={value as string} onChange={onChange} placeholder={placeholder} />;
  }
  if (type === FormInputType.BUSINESS_REGION) {
    return <BusinessRegionInput value={value as string} onChange={onChange} placeholder={placeholder} />;
  }
  if (type === FormInputType.GENDER) {
    return <GenderInput value={value as string} onChange={onChange} placeholder={placeholder} />;
  }
  if (type === FormInputType.AGE) {
    return <Input type="number" value={value as string} onChange={onChange} placeholder={placeholder} />;
  }
  if (type === FormInputType.EMAIL) {
    return <Input type="email" value={value as string} onChange={onChange} placeholder={placeholder} />;
  }
  return <Input value={value as string} onChange={onChange} placeholder={placeholder} />;
};
