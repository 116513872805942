import styled from 'styled-components';
import { IThemeStyled } from 'interfaces/ThemeStyled';

export const InputStyled = styled.input(({ inputColor }: { inputColor: string; theme: IThemeStyled }) => ({
  width: '100%',
  height: 56, // TODO: Change min value with all the other values
  borderRadius: 0,
  borderWidth: 0,
  borderBottomWidth: 2,
  borderBottomColor: inputColor,
  backgroundColor: 'transparent',
  paddingLeft: 12,
  paddingRight: 12,
  fontSize: 18,
  outline: 'none',
  transition: '0.3s ease-in-out',

  '::placeholder': {
    color: inputColor,
    opacity: 0.5,
  },

  '::-webkit-outer-spin-button, ::-webkit-inner-spin-button, input[type=number]': {
    '-webkit-appearance': 'none',
    '-moz-appearance': 'textfield',
    margin: 0,
  },
}));
