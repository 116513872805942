/* eslint-disable jsx-a11y/anchor-is-valid */
import { IGameCenterPopUp } from '@flarie/common';
import { FC, ReactNode } from 'react';
import { shallow } from 'zustand/shallow';
import { DynamicFontLoader } from 'components/dynamic-font-loader/DynamicFontLoader';
import { CloseIcon } from 'components/icons/CloseIcon';
import { getPopUpStyle } from 'components/popup/styles';
import { useGameCenterSettingStore } from 'store/GameCenterSettingStore';
import { CSSProperties } from 'styled-components';

interface Props {
  onCloseModal: () => void;
  popupText: ReactNode;
}
const ClosePopup: FC<Props> = ({ onCloseModal, popupText }) => {
  const [popup] = useGameCenterSettingStore((state) => [state.popup, state.userBase], shallow);

  const styles = getPopUpStyle(popup || ({} as IGameCenterPopUp));

  return (
    <div className="relative mx-auto w-[70%] rounded-2xl px-6 pb-4 pt-4 shadow-lg" style={styles.pageStyle}>
      <button
        type="button"
        className=" ml-auto flex h-6 w-6 items-center justify-center rounded-lg active:scale-90 active:bg-ash"
        onClick={onCloseModal}
      >
        <CloseIcon color={styles?.pageStyle.color} />
      </button>
      <div className="ml-auto mr-auto mt-2 max-w-[300px] text-center">{popupText}</div>
      <DynamicFontLoader fontId={popup?.closeButtonStyle?.font?.fontFamily || ''} fontName="close-button-font">
        <div className="ml-auto mr-auto mt-4 max-w-[300px]">
          <button
            onClick={onCloseModal}
            type="button"
            className="mx-[auto] my-[0] mt-3 block min-h-[56px] w-full"
            style={styles.closeButtonStyle as CSSProperties}
          >
            {popup?.closeButtonText}
          </button>
        </div>
      </DynamicFontLoader>
    </div>
  );
};
export default ClosePopup;
