/* eslint-disable react/no-danger */
import DOMPurify from 'dompurify';

interface Props {
  htmlText: string;
  isInheritColor?: boolean;
}

export const RenderHtmlText: React.FC<Props> = ({ htmlText, isInheritColor = false }: Props): JSX.Element => {
  // eslint-disable-next-line require-unicode-regexp
  const sanitizedHTML = htmlText.replace(/^\s+|\s+$/g, '');

  return (
    <div
      className={isInheritColor ? 'html-text-inherit-color' : 'html-text-wrapper'}
      dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(sanitizedHTML, { ADD_ATTR: ['target'] }) }}
    />
  );
};
