import { CustomizationKeyType } from '@flarie/common';
import { useEffect } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import AppRoutes from 'routes/AppRoutes';
import setAxiosConfig from 'services/AxiosConfig';
import { postLoadingComplete } from 'utils/post-loading-complete';

const queryClient = new QueryClient();

const App = () => {
  // TODO: This should not be in the render scope, this method should've been inside a useEffect. 
  // So we need to update this and test.
  setAxiosConfig(CustomizationKeyType.Null, '');

  useEffect(() => {
      // Sending postMessage so that third party clients (like ooredoo) can listen and hide/show their loader
      postLoadingComplete();
  }, [])

  return (
    <QueryClientProvider client={queryClient}>
      <AppRoutes />
    </QueryClientProvider>
  );
};

export default App;
