import { shallow } from 'zustand/shallow';
import { IGameCenterPopUp } from '@flarie/common';
import { Button } from 'components/common/button/Button';
import { SignInModal } from 'components/sign-in-modal/SignInModal';
import { useGameCenterSettingStore } from 'store/GameCenterSettingStore';
import { StyleConverterService } from 'services/StyleConverterService';

interface Props {
  onCloseModal: () => void;
}
export const PlayerAuthComplete = ({ onCloseModal }: Props) => {
  const [userBase, popup] = useGameCenterSettingStore((state) => [state.userBase, state.popup], shallow);

  return (
    <>
      <SignInModal.Title>{userBase?.signUpVerificationText}</SignInModal.Title>
      <div className="flex h-24" />
      <PlayerAuthComplete.CloseButton popup={popup as IGameCenterPopUp} onClick={onCloseModal} />
    </>
  );
};

PlayerAuthComplete.CloseButton = ({ popup, onClick }: { popup: IGameCenterPopUp; onClick: () => void }) => {
  const styleConverter = new StyleConverterService();

  const convertedButtonStyle = {
    ...styleConverter.convertIStyleToCSS(popup?.closeButtonStyle),
  };
  convertedButtonStyle.width = '100%';

  return (
    <Button onClick={onClick} style={convertedButtonStyle}>
      {popup?.closeButtonText || 'Close'}
    </Button>
  );
};
