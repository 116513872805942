// TODO: Temporary: remove after style-bar-2.0 migration

/* eslint-disable no-restricted-syntax */
/* eslint-disable consistent-return */
import {
  Alignment,
  defaultBrandEmail,
  defaultGameCenter,
  DynamoDbEntityType,
  IGameCenterGame,
  IGameCenterGameModule,
  IGameCenterRewardModule,
  IGameCenterTextModule,
} from '@flarie/common';
import { IPlayerGameCenterDetail } from 'interfaces/PlayerGameCenterDetail';
import { mapStyleData } from 'utils/old-style-mapper';

const migrateGameCenterGameModule = (gameCenterGameModule: IGameCenterGameModule): any => {
  try {
    if (!gameCenterGameModule) {
      return;
    }

    const payload: Partial<IGameCenterGameModule> = {
      headerTitleStyle: mapStyleData(
        defaultGameCenter.gameModule.headerTitleStyle,
        gameCenterGameModule.headerTitleStyle as any,
        1.5,
      ),
      descriptionStyle: mapStyleData(
        defaultGameCenter.gameModule.descriptionStyle,
        gameCenterGameModule.descriptionStyle as any,
        1.5,
      ),
    };

    if (gameCenterGameModule?.games.length > 0) {
      const gameModuleGames: IGameCenterGame[] = [];

      for (const gameCenterGame of gameCenterGameModule.games) {
        gameModuleGames.push({
          ...gameCenterGame,
          gameImageBackgroundStyle: mapStyleData(
            defaultGameCenter.game.gameImageBackgroundStyle,
            gameCenterGame.gameImageBackgroundStyle as any,
          ),
        });
      }

      payload.games = gameModuleGames;
    }

    return payload;
  } catch (error) {
    return gameCenterGameModule;
  }
};

const migrateGameCenterRewardModule = (gameCenterRewardModule: IGameCenterRewardModule): any => {
  try {
    if (!gameCenterRewardModule) {
      return;
    }
    const payload: Partial<IGameCenterRewardModule> = {
      headerTitleStyle: mapStyleData(
        defaultGameCenter.rewardModule.headerTitleStyle,
        gameCenterRewardModule.headerTitleStyle as any,
        1.5,
      ),
      descriptionStyle: mapStyleData(
        defaultGameCenter.rewardModule.descriptionStyle,
        gameCenterRewardModule.descriptionStyle as any,
        1.5,
      ),
    };

    return payload;
  } catch (error) {
    return gameCenterRewardModule;
  }
};

const migrateGameCenterTextModule = (gameCenterTextModule: IGameCenterTextModule): any => {
  try {
    if (!gameCenterTextModule) {
      return;
    }

    const payload: Partial<IGameCenterTextModule> = {
      headerTitleStyle: mapStyleData(
        defaultGameCenter.textModule.headerTitleStyle,
        gameCenterTextModule.headerTitleStyle as any,
        1.5,
      ),
      descriptionStyle: mapStyleData(
        defaultGameCenter.textModule.descriptionStyle,
        gameCenterTextModule.descriptionStyle as any,
        1.5,
      ),
    };

    return payload;
  } catch (error) {
    return gameCenterTextModule;
  }
};

export const applyToNewStyle = async (data: IPlayerGameCenterDetail): Promise<IPlayerGameCenterDetail> => {
  const { gameCenter } = data;

  // GameCenter Details convert
  gameCenter.detail.headerTitleStyle = mapStyleData(
    defaultGameCenter.detail.headerTitleStyle,
    gameCenter.detail.headerTitleStyle as any,
    1.5,
  );

  // GameCenter Email convert
  const convertedEmailStyle = {
    buttonStyle: mapStyleData(defaultBrandEmail.buttonStyle, gameCenter.email.buttonStyle as any),
    headerTextStyle: mapStyleData(defaultBrandEmail.headerTextStyle, gameCenter.email.headerTextStyle as any),
    bodyTextStyle: mapStyleData(defaultBrandEmail.bodyTextStyle, gameCenter.email.bodyTextStyle as any),
  };
  gameCenter.email = { ...gameCenter.email, ...convertedEmailStyle };

  // GameCenter RewardSetting convert
  const convertedRewardSettings = {
    headerFontStyle: mapStyleData(
      defaultGameCenter.rewardSettings.headerFontStyle,
      gameCenter.rewardSettings.headerFontStyle as any,
      1.5,
    ),
    descriptionTextStyle: mapStyleData(
      defaultGameCenter.rewardSettings.descriptionTextStyle,
      gameCenter.rewardSettings.descriptionTextStyle as any,
      1.5,
    ),
    buttonStyle: mapStyleData(
      defaultGameCenter.rewardSettings.buttonStyle,
      gameCenter.rewardSettings.buttonStyle as any,
      1.5,
    ),
  };
  gameCenter.rewardSettings = { ...gameCenter.rewardSettings, ...convertedRewardSettings };

  // GameCenter popup settings convert
  const convertedPopupSettings = {
    confirmButtonStyle: mapStyleData(
      defaultGameCenter.popup.confirmButtonStyle,
      gameCenter.popup.confirmButtonStyle as any,
      1.5,
    ),
    closeButtonStyle: mapStyleData(
      defaultGameCenter.popup.closeButtonStyle,
      gameCenter.popup.closeButtonStyle as any,
      1.5,
    ),
    nextButtonStyle: mapStyleData(
      defaultGameCenter.popup.nextButtonStyle,
      gameCenter.popup.nextButtonStyle as any,
      1.5,
    ),
  };
  gameCenter.popup = { ...gameCenter.popup, ...convertedPopupSettings };

  // GameCenter modules convert
  const modules = [];
  for (const gameCenterModule of gameCenter.modules) {
    if (gameCenterModule.entityType === DynamoDbEntityType.GAME_CENTER_GAME_MODULE) {
      const convertedModule = migrateGameCenterGameModule(gameCenterModule as IGameCenterGameModule);
      modules.push({ ...gameCenterModule, ...convertedModule });
    }

    if (gameCenterModule.entityType === DynamoDbEntityType.GAME_CENTER_TEXT_MODULE) {
      const convertedModule = migrateGameCenterTextModule(gameCenterModule as IGameCenterTextModule);
      modules.push({ ...gameCenterModule, ...convertedModule });
    }

    if (gameCenterModule.entityType === DynamoDbEntityType.GAME_CENTER_REWARD_MODULE) {
      const convertedModule = migrateGameCenterRewardModule(gameCenterModule as IGameCenterRewardModule);
      modules.push({ ...gameCenterModule, ...convertedModule });
    }

    if (gameCenterModule.entityType === DynamoDbEntityType.GAME_CENTER_AD_MODULE) {
      modules.push(gameCenterModule);
    }
  }

  gameCenter.modules = modules;

  return { ...data, gameCenter: { ...gameCenter } };
};
