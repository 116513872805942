import { Alignment, defaultGameCenter, IStyle, TextDecorationType } from '@flarie/common';
import { StyleConverterService } from 'services/StyleConverterService';

interface IStyleObject {
  headerTitleStyle: Partial<IStyle>;
}

export const getHeaderStyle = (
  { headerTitleStyle }: IStyleObject,
  currencyTextColor: string,
  isWalletActive: boolean,
) => {
  const styleConverter = new StyleConverterService();

  const convertedHeaderTitleStyle = styleConverter.convertIStyleToCSS(headerTitleStyle);
  const defaultHeaderTitleStyle = styleConverter.convertIStyleToCSS(
    defaultGameCenter.detail.headerTitleStyle as IStyle,
  );

  let textAlignClassName = 'mr-auto';

  if (convertedHeaderTitleStyle?.textAlign === Alignment.RIGHT) {
    textAlignClassName = 'ml-auto';
  }

  if (convertedHeaderTitleStyle?.textAlign === Alignment.CENTER) {
    if (isWalletActive) {
      textAlignClassName = 'mx-[auto] my-[0] pl-[58px]';
    } else {
      textAlignClassName = 'mx-[auto] my-[0]';
    }
  }

  return {
    headerTitleStyle: {
      color: convertedHeaderTitleStyle.color || defaultHeaderTitleStyle.color,
      fontSize: convertedHeaderTitleStyle.fontSize || defaultHeaderTitleStyle.fontSize,
      letterSpacing: convertedHeaderTitleStyle.letterSpacing || defaultHeaderTitleStyle.letterSpacing,
      lineHeight: convertedHeaderTitleStyle.lineHeight || defaultHeaderTitleStyle.lineHeight,
      textAlign: convertedHeaderTitleStyle.textAlign || defaultHeaderTitleStyle.textAlign,
      fontFamily: 'inherit',
      fontWeight: convertedHeaderTitleStyle.fontWeight || 'bold',
      textShadow: convertedHeaderTitleStyle.textShadow || '',
      textDecoration: convertedHeaderTitleStyle.textDecoration || TextDecorationType.NONE,
    },
    headerBorderStyle: {
      borderBottom: `${convertedHeaderTitleStyle.borderWidth}`,
      borderStyle: `${convertedHeaderTitleStyle.borderStyle}`,
      borderColor: `${convertedHeaderTitleStyle.borderColor}`,
    },
    currencyTextStyle: {
      color: `#${currencyTextColor || defaultGameCenter.detail.currencyTextColor}`,
    },
    textAlignClassName,
  };
};
