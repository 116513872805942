import { IStyle } from '@flarie/common';
import { StyleConverterService } from 'services/StyleConverterService';

interface IStyleObject {
  headerFontStyle: Partial<IStyle>;
  descriptionStyle?: Partial<IStyle>;
}

export const getSectionStyle = ({ headerFontStyle, descriptionStyle }: IStyleObject) => {
  const styleConverter = new StyleConverterService();

  const convertedHeaderFontStyle = styleConverter.convertIStyleToCSS(headerFontStyle);
  const convertedDescriptionStyle = styleConverter.convertIStyleToCSS(descriptionStyle || {});

  return {
    headerFontStyle: {
      ...convertedHeaderFontStyle,
      fontFamily: 'inherit',
    },
    descriptionStyle: {
      ...convertedDescriptionStyle,
      fontFamily: 'inherit',
    },
  };
};
