/* eslint-disable no-empty */
export const postLoadingComplete = () => {
  const payloadObject = { loading: { progress: 100 } };
  const payload = JSON.stringify(payloadObject);
  try {
    // @ts-ignore
    webkit.messageHandlers.callback.postMessage(payload);
  } catch (err) {}
  try {
    (window as any).ReactNativeWebView.postMessage(payload);
  } catch (err) {
    /* console.log('gameOver Error on iOS.' + _status); */
  }
  try {
    // @ts-ignore
    Android.callback(payload);
  } catch (err) {}
  try {
    // @ts-ignore
    Flutter.postMessage(payload);
  } catch (err) {
    /* console.log("Flutter callback failed" + err); */
  }
  try {
    window.parent.postMessage({ type: 'loading', value: payload }, '*');
  } catch (err) {}
}